import Vue from 'vue'
import Router from 'vue-router'
import Orders from '../pages/Orders'
import History from '../pages/History'
import Faq from '../pages/Faq'
import Projects from '../pages/Projects'
import ViewProject from '../pages/ViewProject'
import MyAccount from '../pages/MyAccount'
import CreateOrder from '../pages/CreateOrder'
import ViewOrder from '../pages/ViewOrder'
import EditOrder from '../pages/EditOrder'
import Activation from '../components/Layouts/Activation'
import Login from '../components/Layouts/Login'
import i18n from "@/i18n";
import ForgotPassword from "@/components/Layouts/ForgotPassword";
import ResetPassword from "@/components/Layouts/ResetPassword";
import PalletHistory from "@/pages/PalletHistory";

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/activate/:token',
      name: 'activation',
      props: true,
      component: Activation,
      meta: { title: i18n.t('activation-label') }
    },{
      path: '/reset-password/:token',
      name: 'resetPassword',
      props: true,
      component: ResetPassword,
      meta: { title: i18n.t('reset-password-label') }
    },{
      path: '/login',
      name: 'login',
      props: true,
      component: Login,
      meta: { title: i18n.t('login-label') }
    },{
      path: '/login/forgot-password',
      name: 'forgotPassword',
      props: true,
      component: ForgotPassword,
      meta: { title: i18n.t('i-forgot-password-label') }
    },{
      path: '/projects',
      name: 'Projects',
      component: Projects,
      meta: { title: i18n.t('projects-label') }
    },
    {
      path: '/my-account',
      name: 'myAccount',
      component: MyAccount,
      meta: { title: i18n.t('my-account-label') }
    },
    {
      path: '/',
      name: 'Orders',
      component: Orders,
      meta: { title: i18n.t('orders-label') },
      props: true,
    },
    {
      path: '/history',
      name: 'History',
      component: History,
      meta: { title: i18n.t('history-label') },
      props: true,
    },
    {
      path: '/pallet-history',
      name: 'Pallet History',
      component: PalletHistory,
      meta: { title: i18n.t('pallets-label') },
      props: true,
    },
    {
      path: '/faq',
      name: 'Faq',
      component: Faq,
      meta: { title: i18n.t('faq-label') },
      props: true,
    },
    {
      path: '/orders/create/:type',
      name: 'create',
      component: CreateOrder,
      meta: { title: i18n.t('create-order-label') },
      props: true
    },
    {
      path: '/order/:id',
      name: 'view',
      component: ViewOrder,
      meta: { title: i18n.t('view-order-label') },
      props: true,
    },
    {
      path: '/order/:id/edit',
      name: 'edit',
      component: EditOrder,
      meta: { title: i18n.t('edit-order-label')},
      props: true,
    },
    {
      path: '/project/:id',
      name: 'show',
      component: ViewProject,
      meta: { title: i18n.t('view-project-label') },
      props: true,
    }

  ]
})
