<template>
  <div>
    <div class="type-image" v-if="this.$attrs.type === 'normal-label' && !this.$attrs.project"><img src="../../assets/pannon-garden.svg"/></div>
    <div class="type-image" v-if="this.$attrs.type === 'mediterranean-label'"><img src="../../assets/pannon-mediterran.svg"/></div>
    <div class="type-image" v-if="this.$attrs.type === 'normal-label' && this.$attrs.project"><img src="../../assets/pannon-sport.svg"/></div>
  </div>
</template>


<script>

export default {
  name: 'Type',
  data(){
    return {
      type: null,
      project: false
    }
  }
};
</script>
